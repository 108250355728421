<template>
  <v-app>
    <v-app-bar :elevation="4">
      <template v-slot:prepend>
        <v-avatar :tile="true">
          <v-img 
          :src="require('@/assets/logo.png')" 
          alt="logo" 
          class="logo" />
        </v-avatar>
        <v-toolbar-title>Blazin' Firewood</v-toolbar-title>
      </template>
    </v-app-bar>
    <v-main class="bg-img">
      <v-container fluid>
        <router-view/>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'App',
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

.bg-img {
  background: url('./assets/woodMtn.png');
  height: 100%;
  background-size: cover;
}

</style>
